import { useState } from 'react';
import useSessionStorage from '../../hooks/useSessionStorage';
import useBrightness from '../../hooks/useBrightness.js';
import CreateUser from './CreateUser';
import UserSelect from './UserSelect';

const Login = (props) => {
  const { onLogin, userCap = 5 } = props;
  const [users, setUsers] = useSessionStorage('users', []);
  const [isCreating, setIsCreating] = useState(users.length === 0);
  useBrightness(0.5);

  const createHandler = () => {
    if (users.length < userCap) setIsCreating(true);
  };

  const backHandler = () => {
    setIsCreating(false);
  };

  const submitHandler = (user) => {
    if (users.length < userCap) setUsers([...users, user]);
    setIsCreating(false);
  };

  return isCreating ? (
    <CreateUser
      onSubmit={submitHandler}
      onBack={backHandler}
      showBack={users.length > 0}
    />
  ) : (
    <UserSelect users={users} onLogin={onLogin} onCreateUser={createHandler} />
  );
};

export default Login;

import { useState, useMemo } from 'react';
import { useFocusableRef } from '../../hooks/SpatialNavigation';
import useBrightness from '../../hooks/useBrightness.js';
import { FocusableLink } from '../../components/Link';
import { FocusableButton } from '../../components/Button';
import useKeyDown from '../../hooks/useKeyDown.js';
import PreviewFrame from '../../components/PreviewFrame';
import projectData from './projectData.js';
import Project from './Project.js';

const Projects = () => {
  const [index, setIndex] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [isFocused, focusRef, forceFocus] = useFocusableRef();
  useBrightness(0.6);

  const project = useMemo(
    () => projectData.find((_, i) => i === index),
    [index]
  );

  const leftHandler = (e) => {
    if (isFocused) {
      setIndex(index > 0 ? index - 1 : projectData.length - 1);
    }
  };

  const rightHandler = (e) => {
    if (isFocused) {
      setIndex(index < projectData.length - 1 ? index + 1 : 0);
    }
  };

  const closeHandler = () => {
    if (isFocused && project) {
      setShowPreview(false);
    }
  };

  const openHandler = () => {
    forceFocus();
    setShowPreview(true);
  };

  const clickHandler = (e) => {
    forceFocus();
    setIndex(parseInt(e.target.dataset.index));
  };

  const wheelHandler = (e) => {
    forceFocus();
    if (e.deltaY < 0) leftHandler();
    else if (e.deltaY > 0) rightHandler();
  };

  useKeyDown('Enter', () => {
    if (isFocused) openHandler();
  });
  useKeyDown('Escape', closeHandler);
  useKeyDown('ArrowLeft', leftHandler);
  useKeyDown('ArrowRight', rightHandler);

  return (
    <main className="flex flex-column gap FadeIn">
      <div>
        {/*<div className="Project__ListHeader l">Featured</div>*/}
        <div className="Project__ListHeader l">
          All Projects (most - least recent)
        </div>
        <div className="flex align-center nowrap gap">
          <div className="click-icon" onClick={leftHandler}>
            &#9664;
          </div>
          <div
            className="Project__List flex align-center gap"
            ref={focusRef}
            onWheel={wheelHandler}
          >
            {projectData.map((p, i) => (
              <Project
                project={p}
                onClick={clickHandler}
                index={i}
                key={p.id}
                isFocused={isFocused && i === index}
              />
            ))}
          </div>
          <div className="click-icon" onClick={rightHandler}>
            &#9654;
          </div>
        </div>
      </div>
      {isFocused && showPreview ? (
        <PreviewFrame
          title={project.title}
          src={project.page}
          onClose={closeHandler}
          onLeft={leftHandler}
          onRight={rightHandler}
        />
      ) : null}
      <div className="Project__Description flex flex-column gap">
        <div>
          <div className="b xl">{project.title}</div>
          <div className="l">{project.subtitle}</div>
        </div>
        <div>{project.description}</div>
        <div className="flex gap">
          <FocusableLink href={project.page} newTab>
            Page
          </FocusableLink>
          <FocusableLink href={project.source} newTab>
            Github
          </FocusableLink>
          <FocusableButton onInteract={openHandler}>Preview</FocusableButton>
        </div>
      </div>
    </main>
  );
};

export default Projects;

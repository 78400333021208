const PreviewFrame = (props) => {
  const { title, src, onClose, onLeft, onRight } = props;

  const wheelHandler = (e) => {
    if (e.deltaY < 0) onLeft();
    else if (e.deltaY > 0) onRight();
  };

  return (
    <div
      className="PreviewFrame__Container flex justify-space-evenly align-center"
      onWheelCapture={wheelHandler}
    >
      <div className="PreviewFrame__Darken" onClick={onClose} />
      <div className="PreviewFrame__Btn" onClick={onLeft}>
        &#9664;
      </div>
      <div className="PreviewFrame__Inner">
        <div>{title}</div>
        <iframe
          className="PreviewFrame"
          title={title}
          src={src}
          loading="lazy"
        />
      </div>
      <div className="PreviewFrame__Btn" onClick={onRight}>
        &#9654;
      </div>
    </div>
  );
};

export default PreviewFrame;

import { useLayoutEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useRect from '../useRect.js';
import { add, remove, focus } from '../../reducers/navigationReducer';
import { v4 as uuidv4 } from 'uuid';

// rewrite midx/midy to say originx originy
const useFocusableRef = (
  defaultFocus = false,
  originOffsetX = 0.5,
  originOffsetY = 0.5
) => {
  const id = useMemo(uuidv4, []);
  const ref = useRef();
  const rect = useRect(ref);
  const dispatch = useDispatch();
  const isFocused = useSelector((state) => state.navigation.focus === id);
  const forceFocus = () => dispatch(focus(id));

  useLayoutEffect(() => {
    if (rect) {
      const data = {
        id,
        x: rect.x,
        y: rect.y,
        top: rect.top,
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
        midx: rect.x + rect.width * originOffsetX,
        midy: rect.y + rect.height * originOffsetY,
        default: defaultFocus,
      };

      dispatch(add(data));
      return () => dispatch(remove(id));
    }
  }, [id, rect, dispatch, defaultFocus, originOffsetX, originOffsetY]);

  return [isFocused, ref, forceFocus];
};

export default useFocusableRef;

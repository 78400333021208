import { FocusableNavItem } from '../../components/Navigation';
import Clock from '../../components/Clock';
import User from './User.js';

const Header = (props) => {
  const { user, onLogout } = props;

  return (
    <header className="Header flex justify-space-between align-center gap">
      <FocusableNavItem className="Header__User" path="/" defaultFocus>
        <User
          src={user.icon}
          name={user.name}
          onLogout={onLogout}
          defaultFocus
        />
      </FocusableNavItem>
      <nav className="Nav flex gap">
        <FocusableNavItem className="Nav__Item" path="/skills">
          Skills
        </FocusableNavItem>
        <FocusableNavItem className="Nav__Item" path="/projects">
          Projects
        </FocusableNavItem>
        <FocusableNavItem className="Nav__Item" path="/contact">
          Contact
        </FocusableNavItem>
      </nav>
      <Clock className="Header__Clock l" />
    </header>
  );
};

export default Header;

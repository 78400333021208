import { useState, useEffect, useRef } from 'react';
import useKeyDown from '../../hooks/useKeyDown.js';

const Typewriter = (props) => {
  const {
    className = '',
    strings,
    onChar = () => {},
    onPause = () => {},
    onContinue = () => {},
    onFinish = () => {},
    ms = 20,
  } = props;

  const [currentLength, setCurrentLength] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef();

  const currentText =
    strings.length > 0 ? strings[currentIndex].substring(0, currentLength) : '';

  const interactHandler = () => {
    const wishLength = strings[currentIndex].length;
    if (currentLength < wishLength) {
      setCurrentLength(strings[currentIndex].length);
    } else if (currentIndex < strings.length - 1) {
      setCurrentIndex((i) => i + 1);
      setCurrentLength(0);
      onContinue();
    } else {
      onFinish();
    }
  };

  useKeyDown('Enter', interactHandler);
  useKeyDown('Escape', interactHandler);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      if (currentLength < strings[currentIndex].length) {
        setCurrentLength(currentLength + 1);
        onChar();
      } else {
        onPause();
      }
    }, ms);

    return () => clearTimeout(timeoutRef.current);
  }, [strings, onPause, onChar, currentLength, currentIndex, ms]);

  useEffect(() => {
    setCurrentLength(0);
    setCurrentIndex(0);
  }, [strings]);

  return (
    <div className={className} onClick={interactHandler}>
      {currentText}
    </div>
  );
};

export default Typewriter;

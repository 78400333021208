import { useState } from 'react';
import useBrightness from '../../hooks/useBrightness.js';
import { FocusableBar } from '../../components/ProgressBar';
import SkillDescription from './SkillDescription.js';
import skillData from './skillData.js';

const Skills = () => {
  useBrightness(0.6);
  const [skill, setSkill] = useState({
    name: '',
    description: 'Waiting for selection..',
  });

  const interactHandler = (s) => {
    setSkill(s);
  };

  return (
    <main className="Skills__Page FadeIn">
      <div className="Skills__Column flex flex-column gap">
        <div className="text-center l">Developer Skills</div>
        <ul className="flex flex-column gap list l">
          {skillData.map((s) => (
            <li key={s.id}>
              <FocusableBar
                name={s.name}
                onInteract={() => interactHandler(s)}
              />
            </li>
          ))}
        </ul>
      </div>
      <SkillDescription title={skill.name} description={skill.description} />
      <ul className="flex flex-wrap gap list s">
        <li className="SoftSkill">Linux</li>
        <li className="SoftSkill">VSCode</li>
        <li className="SoftSkill">Webpack / Vite</li>
        <li className="SoftSkill">ESLint / Prettier</li>
        <li className="SoftSkill">Bash</li>
      </ul>
    </main>
  );
};

export default Skills;

import { useState } from 'react';
import Typewriter from '../../components/Typewriter';
import Character from './Character.js';

const CharacterDialogue = (props) => {
  const { strings, onFinish } = props;
  const [isCharacterTalking, setIsCharacterTalking] = useState(false);

  const charHandler = (letter) => {
    if (!isCharacterTalking) {
      setIsCharacterTalking(true);
    }
    //sfx.char.play();
  };

  const pauseHandler = () => {
    if (isCharacterTalking) setIsCharacterTalking(false);
  };

  const continueHandler = () => {
    //sfx.continue.play();
  };

  const finishHandler = () => {
    onFinish();
  };

  return (
    <div className="Character__Container">
      <Character isTalking={isCharacterTalking} />
      <div className="Character__Dialogue">
        <Typewriter
          strings={strings}
          onChar={charHandler}
          onContinue={continueHandler}
          onPause={pauseHandler}
          onFinish={finishHandler}
        />
        {!isCharacterTalking ? (
          <div className="Character__Skip flex justify-right">
            Press ⌨ Enter / 🎮 A to continue
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CharacterDialogue;

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSpatialNavigation } from './hooks/SpatialNavigation';
import { useGamepadsMapped } from './hooks/Gamepads';
import useSessionStorage from './hooks/useSessionStorage.js';
import CharacterDialogue from './features/CharacterDialogue';
import dialogueStrings from './data/dialogueStrings.js';
import Header from './features/Header';
import Login from './features/Login';
import About from './pages/About';
import Skills from './pages/Skills';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import DefaultIcon from './assets/icons/default.svg';
import './App.css';

const initialUserState = {
  user: {
    name: 'User',
    icon: DefaultIcon,
  },
  isLoggedIn: true,
};

function App() {
  const [login, setLogin] = useSessionStorage('login', initialUserState);
  const [isIntroduced, setIsIntroduced] = useSessionStorage('intro', false);

  useGamepadsMapped({
    buttons: {
      0: { key: 'Enter' },
      1: { key: 'Enter' },
      9: { key: 'Enter' },
      2: { key: 'Escape' },
      3: { key: 'Escape' },
      12: { key: 'ArrowUp' },
      13: { key: 'ArrowDown' },
      14: { key: 'ArrowLeft' },
      15: { key: 'ArrowRight' },
    },
    axes: {
      0: {
        Negative: { key: 'ArrowLeft' },
        Positive: { key: 'ArrowRight' },
      },
      1: {
        Negative: { key: 'ArrowUp' },
        Positive: { key: 'ArrowDown' },
      },
    },
  });
  useSpatialNavigation();

  const loginHandler = (user) => {
    setLogin({
      user: user,
      isLoggedIn: true,
    });
  };

  const logoutHandler = () => {
    setLogin(initialUserState);
  };

  const introHandler = () => {
    setIsIntroduced(true);
  };

  return (
    <div className="Inner">
      {login.isLoggedIn ? (
        <BrowserRouter>
          <Header user={login.user} onLogout={logoutHandler} />
          {isIntroduced ? (
            <Routes>
              <Route path="/" element={<About />} />
              <Route path="skills" element={<Skills />} />
              <Route path="projects" element={<Projects />} />
              <Route path="contact" element={<Contact />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          ) : (
            <CharacterDialogue
              strings={dialogueStrings}
              onFinish={introHandler}
            />
          )}
        </BrowserRouter>
      ) : (
        <Login onLogin={loginHandler} />
      )}
    </div>
  );
}

export default App;

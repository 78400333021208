import { useRef, useLayoutEffect, useEffect } from 'react';

export default function useGamepads(onUpdate = () => {}) {
  const isConnectCompatible = 'ongamepadconnected' in window;
  const isPollCompatible =
    typeof navigator.getGamepads === 'function' ||
    typeof navigator.webkitGetGamepads === 'function';

  const gamepadsRef = useRef([]);
  const lastUpdateRef = useRef([]);
  const requestRef = useRef();

  const registerGamepad = (gamepad) => {
    gamepadsRef.current = {
      ...gamepadsRef.current,
      [gamepad.index]: gamepad,
    };

    if (!lastUpdateRef.current[gamepad.index]) {
      lastUpdateRef.current = {
        ...lastUpdateRef.current,
        [gamepad.index]: 0,
      };
    }
  };

  const updateGamepads = () => {
    for (const gamepad of Object.values(gamepadsRef.current)) {
      if (gamepad.timestamp > lastUpdateRef.current[gamepad.index]) {
        onUpdate(gamepad);

        lastUpdateRef.current = {
          ...lastUpdateRef.current,
          [gamepad.index]: gamepad.timestamp,
        };
      }
    }
  };

  const step = () => {
    if (!isConnectCompatible) {
      const gamepads = navigator.getGamepads
        ? navigator.getGamepads()
        : navigator.webkitGetGamepads
        ? navigator.webkitGetGamepads()
        : [];

      for (const gamepad of gamepads) {
        if (gamepad) registerGamepad(gamepad);
      }
    }

    updateGamepads();
    requestRef.current = requestAnimationFrame(step);
  };

  useEffect(() => {
    const handleConnect = (event) => registerGamepad(event.gamepad);

    if (isConnectCompatible)
      window.addEventListener('gamepadconnected', handleConnect);
    return () => window.removeEventListener('gamepadconnected', handleConnect);
  }, [isConnectCompatible]);

  useLayoutEffect(() => {
    if (isConnectCompatible || isPollCompatible)
      requestRef.current = requestAnimationFrame(step);

    return () => {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
    };
  });
}

import { useFocusableRef } from '../../hooks/SpatialNavigation';
import useKeyDown from '../../hooks/useKeyDown';

const FocusableLink = (props) => {
  const { className = '', children, href, newTab = false } = props;

  const [isFocused, focusRef] = useFocusableRef();

  useKeyDown('Enter', (e) => {
    if (isFocused) {
      e.preventDefault();
      focusRef.current.click();
    }
  });

  return newTab ? (
    <a
      className={`Link ${className} ${isFocused ? 'Focused' : ''}`}
      href={href}
      target="_blank"
      rel="noreferrer"
      ref={focusRef}
    >
      {children}
    </a>
  ) : (
    <a
      className={`Link ${className} ${isFocused ? 'Focused' : ''}`}
      href={href}
      ref={focusRef}
    >
      {children}
    </a>
  );
};

export default FocusableLink;

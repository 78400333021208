const SkillDescription = (props) => {
  const { title, description } = props;

  return (
    <div className="Skills__Column">
      <div className="text-center l">{title}</div>
      <div key={title} className="FadeIn">
        {description}
      </div>
    </div>
  );
};

export default SkillDescription;

import CharacterIdle from '../../assets/character/idle.png';
import CharacterTalking from '../../assets/character/talking.png';

const Character = (props) => {
  const { className = '', isTalking } = props;

  return (
    <img
      className={`Character responsive-img ${className} ${
        isTalking ? 'Character__Talking' : ''
      }`}
      src={isTalking ? CharacterTalking : CharacterIdle}
      alt="Animated character"
      draggable="false"
    />
  );
};

export default Character;

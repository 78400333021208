import { useFocusableRef } from '../../hooks/SpatialNavigation';
import useKeyDown from '../../hooks/useKeyDown';

const FocusableButton = (props) => {
  const {
    className = '',
    type = 'button',
    onInteract,
    children,
    defaultFocus = false,
  } = props;
  const [isFocused, focusRef] = useFocusableRef(defaultFocus);

  useKeyDown('Enter', (e) => {
    if (isFocused) {
      e.preventDefault();
      onInteract();
    }
  });

  return (
    <button
      type={type}
      className={`Button ${isFocused ? 'Focused' : ''} ${className}`}
      onClick={onInteract}
      ref={focusRef}
    >
      {children}
    </button>
  );
};

export default FocusableButton;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { up, down, left, right } from '../../reducers/navigationReducer';

const useSpatialNavigation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleKey = (e) => {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        dispatch(up());
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        dispatch(down());
      } else if (e.key === 'ArrowLeft') {
        e.preventDefault();
        dispatch(left());
      } else if (e.key === 'ArrowRight') {
        e.preventDefault();
        dispatch(right());
      }
    };

    window.addEventListener('keydown', handleKey);
    return () => window.removeEventListener('keydown', handleKey);
  }, [dispatch]);
};

export default useSpatialNavigation;

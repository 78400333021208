import { FocusableButton } from '../../components/Button';
import { FocusableAvatar } from '../../components/Avatar';

const UserSelect = (props) => {
  const { users, onLogin, onCreateUser } = props;

  return (
    <div className="Login flex flex-column justify-space-evenly align-center gap fadeIn">
      <div className="flex flex-column gap">
        <div className="text-center l text-shadow">Select a user</div>
        <ul className="flex justify-center gap list">
          {users.map((user, i) => (
            <li key={user.id} className="flex flex-column align-center">
              <FocusableAvatar
                className="Login__Icon circle"
                src={user.icon}
                alt={user.name}
                onInteract={() => onLogin(user)}
                defaultFocus={i === 0}
              />
              <div className="text-shadow">{user.name}</div>
            </li>
          ))}
        </ul>
      </div>
      <FocusableButton
        className="Login__Btn text-shadow"
        onInteract={onCreateUser}
      >
        Create New User
      </FocusableButton>
    </div>
  );
};

export default UserSelect;

const TopLeftButton = (props) => {
  const { className = '', children, onClick } = props;

  return (
    <button
      className={`TopLeftButton ${className}`}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default TopLeftButton;

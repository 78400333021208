import { useRef, useCallback } from 'react';
import useGamepadCallbacks from './useGamepadCallbacks';

export default function useGamepadsMapped(
  actionMap = {
    buttons: {
      0: { key: 'Enter' },
      1: { key: 'Shift' },
      9: { key: 'Escape' },
      12: { key: 'ArrowUp' },
      13: { key: 'ArrowDown' },
      14: { key: 'ArrowLeft' },
      15: { key: 'ArrowRight' },
    },
    axes: {
      0: {
        Negative: { key: 'ArrowLeft' },
        Positive: { key: 'ArrowRight' },
      },
      1: {
        Negative: { key: 'ArrowUp' },
        Positive: { key: 'ArrowDown' },
      },
    },
  },
  deadzone = 0.4
) {
  const lastAxesRef = useRef([]);

  const handleButtonUp = (data) => {
    const mappedButton = actionMap.buttons?.[data.index];
    if (mappedButton !== undefined) {
      window.dispatchEvent(new KeyboardEvent('keyup', mappedButton));
    }
  };

  const handleButtonDown = (data) => {
    const mappedButton = actionMap.buttons?.[data.index];
    if (mappedButton !== undefined) {
      window.dispatchEvent(new KeyboardEvent('keydown', mappedButton));
    }
  };

  const dispatchAxis = useCallback(
    (type, axis, direction) => {
      const mappedAxis = actionMap.axes?.[axis];
      if (mappedAxis !== undefined) {
        let data = undefined;
        if (direction === -1) data = mappedAxis.Negative;
        else if (direction === 1) data = mappedAxis.Positive;
        else if (direction === 0) data = mappedAxis.Neutral;

        if (data !== undefined)
          window.dispatchEvent(new KeyboardEvent(type, data));
      }
    },
    [actionMap.axes]
  );

  const handleAxisChange = useCallback(
    (data) => {
      const dir = data.value > deadzone ? 1 : data.value < -deadzone ? -1 : 0;
      const lastDir = lastAxesRef.current[data.index];

      const isNewInput = dir !== lastDir;
      const isCurrentPressed = dir !== 0;
      const wasLastReleased = lastDir !== 0;
      const isCacheDirty = lastDir === undefined || isNewInput;

      if (lastDir !== undefined) {
        if (isNewInput) {
          if (isCurrentPressed) dispatchAxis('keydown', data.index, dir);
          if (wasLastReleased) dispatchAxis('keyup', data.index, lastDir);
        }
      } else if (isCurrentPressed) {
        dispatchAxis('keydown', data.index, dir);
      }

      if (isCacheDirty) {
        lastAxesRef.current = {
          ...lastAxesRef.current,
          [data.index]: dir,
        };
      }
    },
    [dispatchAxis, deadzone]
  );

  useGamepadCallbacks({
    onButtonUp: handleButtonUp,
    onButtonDown: handleButtonDown,
    onAxisChange: handleAxisChange,
  });
}

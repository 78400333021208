import { useState, useEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const useRect = (ref) => {
  const [rect, setRect] = useState({});

  // Implement deep comparison + potentially debounce/throttle
  const set = () => {
    setRect(ref && ref.current ? ref.current.getBoundingClientRect() : {});
  };

  useResizeObserver(ref, set);

  const useEffectInEvent = (evt, useCapture) => {
    useEffect(() => {
      set();
      window.addEventListener(evt, set, useCapture);
      return () => window.removeEventListener(evt, set, useCapture);
    }, [evt, useCapture]);
  };

  useEffectInEvent('resize');
  useEffectInEvent('scroll', true);

  return rect;
};

export default useRect;

import { useEffect } from 'react';
import { useFocusableRef } from '../../hooks/SpatialNavigation';
import useKeyDown from '../../hooks/useKeyDown';

const FocusableTextField = (props) => {
  const {
    className = '',
    onChange,
    value,
    placeholder = '',
    minLength = 0,
    maxLength = 0,
    defaultFocus = false,
  } = props;

  const [isFocused, focusRef, forceFocus] = useFocusableRef(defaultFocus);

  useKeyDown('Enter', () => {
    focusRef.current.blur();
  });

  useEffect(() => {
    if (isFocused) {
      focusRef.current.focus();
    } else {
      focusRef.current.blur();
    }
  }, [isFocused, focusRef]);

  return (
    <input
      className={`TextField ${className} ${
        isFocused ? 'Focused' : ''
      } text-center`}
      type="text"
      onChange={onChange}
      value={value}
      onClick={forceFocus}
      placeholder={placeholder}
      minLength={minLength}
      maxLength={maxLength}
      ref={focusRef}
    />
  );
};

export default FocusableTextField;

import { useEffect } from 'react';
import { useFocusableRef } from '../../hooks/SpatialNavigation';

const FocusableBar = (props) => {
  const { className = '', name, onInteract } = props;
  const [isFocused, focusRef, forceFocus] = useFocusableRef();

  useEffect(() => {
    if (isFocused) onInteract();
  }, [isFocused, onInteract]);

  const clickHandler = () => {
    forceFocus();
    onInteract();
  };

  return (
    <div
      className={`ProgressBar ${className} ${isFocused ? 'Focused' : ''}`}
      onClick={clickHandler}
      ref={focusRef}
    >
      {name}
    </div>
  );
};

export default FocusableBar;

import { useState } from 'react';
import useKeyDown from '../../hooks/useKeyDown';
import { Avatar, FocusableAvatar } from '../../components/Avatar';
import { FocusableTextField } from '../../components/Input';
import { FocusableButton, TopLeftButton } from '../../components/Button';
import DefaultIcon from '../../assets/icons/default.svg';
import Shapes from '../../assets/icons/shapes.webp';
import Controller from '../../assets/icons/controller.webp';
import Computer from '../../assets/icons/computer.webp';
import Code from '../../assets/icons/code.webp';
import { v4 as uuidv4 } from 'uuid';

const CreateUser = (props) => {
  const { onSubmit, onBack, showBack } = props;
  const [user, setUser] = useState({
    name: 'New User',
    icon: DefaultIcon,
    id: uuidv4(),
  });

  useKeyDown('Escape', () => {
    if (showBack) onBack();
  });

  const nameHandler = (e) => {
    setUser({
      ...user,
      name: e.target.value,
    });
  };

  const iconHandler = (i) => {
    setUser({
      ...user,
      icon: i,
    });
  };

  const submitHandler = () => {
    onSubmit(user);
  };

  return (
    <div className="Login flex flex-column justify-space-evenly gap fadeIn">
      {showBack ? (
        <TopLeftButton onClick={onBack}>&#9664;</TopLeftButton>
      ) : null}
      <div className="text-center xl text-shadow">Creating User</div>
      <div>
        <form className="Login__Form flex flex-column align-center">
          <Avatar
            className="Login__IconChosen circle"
            src={user.icon}
            alt={user.name}
          />
          <FocusableTextField
            className="b"
            onChange={nameHandler}
            value={user.name}
            placeholder="Example Name"
            minLength={1}
            maxLength={12}
            defaultFocus
          />
          <FocusableButton
            className="Login__Btn"
            type="submit"
            onInteract={submitHandler}
          >
            Submit
          </FocusableButton>
        </form>
      </div>
      <div className="flex flex-column align-center gap">
        <div className="text-shadow">Choose an icon</div>
        <ul className="Login__IconList list flex">
          <li>
            <FocusableAvatar
              className="Login__Icon circle"
              src={DefaultIcon}
              alt="Default icon"
              onInteract={() => iconHandler(DefaultIcon)}
            />
          </li>
          <li>
            <FocusableAvatar
              className="Login__Icon circle"
              src={Shapes}
              alt="Shapes icon"
              onInteract={() => iconHandler(Shapes)}
            />
          </li>
          <li>
            <FocusableAvatar
              className="Login__Icon circle"
              src={Controller}
              alt="Controller icon"
              onInteract={() => iconHandler(Controller)}
            />
          </li>
          <li>
            <FocusableAvatar
              className="Login__Icon circle"
              src={Computer}
              alt="Computer icon"
              onInteract={() => iconHandler(Computer)}
            />
          </li>
          <li>
            <FocusableAvatar
              className="Login__Icon circle"
              src={Code}
              alt="Code icon"
              onInteract={() => iconHandler(Code)}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CreateUser;

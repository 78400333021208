import {
  Dropdown,
  DropdownHeader,
  DropdownList,
  DropdownListItem,
} from '../../components/Dropdown';
import { Avatar } from '../../components/Avatar';

const User = (props) => {
  const { src, name, onLogout } = props;

  // Onclick and onLogout are only needed as temporary functionality
  /*
  const clickHandler = () => {
    onLogout();
  };

  useKeyDown('Enter', () => {
    if (isFocused) {
      onLogout();
    }
  });
  */

  return (
    <Dropdown>
      <DropdownHeader className="flex align-center gap">
        <Avatar className="circle" src={src} alt={name} />
        <div className="Header__UserName">{name}</div>
      </DropdownHeader>
      <DropdownList>
        <DropdownListItem onSelect={onLogout}></DropdownListItem>
      </DropdownList>
    </Dropdown>
  );
};

export default User;

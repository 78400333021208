import { useFocusableRef } from '../../hooks/SpatialNavigation';
import useKeyDown from '../../hooks/useKeyDown';

const FocusableAvatar = (props) => {
  const { className = '', src, alt, onInteract, defaultFocus = false } = props;
  const [isFocused, focusRef, forceFocus] = useFocusableRef(defaultFocus);

  useKeyDown('Enter', (e) => {
    if (isFocused) {
      e.preventDefault();
      onInteract(e);
    }
  });

  const clickHandler = (e) => {
    forceFocus();
    onInteract(e);
  };

  return (
    <img
      src={src}
      alt={alt}
      className={`FocusableAvatar Avatar ${
        isFocused ? 'Focused' : ''
      } ${className}`}
      onClick={clickHandler}
      ref={focusRef}
    />
  );
};

export default FocusableAvatar;

import useBrightness from '../../hooks/useBrightness.js';
import CharacterPortrait from '../../assets/character/portrait.png';
import Container from '../../components/Container';

const About = () => {
  useBrightness(0.4);

  return (
    <main className="About FadeIn">
      <Container>
        <div className="About__Container">
          <img
            className="About__CharacterBubble responsive-img"
            src={CharacterPortrait}
            alt="Character Portrait"
          />
          <div>
            <div className="xl b text-shadow">Hello, I'm Chadwick Blewett</div>
            <div className="l text-shadow">
              Front-end developer based in the US. Lover of all things
              programming. I enjoy working with front-end frameworks and am
              comfortable working with other languages.
            </div>
          </div>
        </div>
        <div className="About__SkillSummary flex justify-center">
          <div>
            <div className="xl b">Primary Languages</div>
            <ul className="list l">
              <li>Javascript / Typescript</li>
              <li>GameMaker Language</li>
              <li>C#</li>
            </ul>
          </div>
          <div>
            <div className="xl b">Tools / Frameworks</div>
            <ul className="list l">
              <li>React + Redux</li>
              <li>GameMaker Studio 2</li>
              <li>.NET</li>
            </ul>
          </div>
        </div>
      </Container>
    </main>
  );
};

export default About;

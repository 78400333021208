import { useEffect } from 'react';

const useBrightness = (value) => {
  useEffect(() => {
    const darkness = 1 - Math.max(0, Math.min(1.0, parseFloat(value)));
    document.documentElement.style.setProperty('--darken', darkness);
  }, [value]);
};

export default useBrightness;

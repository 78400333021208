import { useEffect, useRef } from 'react';

const Project = (props) => {
  const { project, onClick, index, isFocused } = props;
  const focusRef = useRef();

  useEffect(() => {
    if (isFocused) {
      focusRef.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      });

      document.documentElement.style.setProperty(
        '--background',
        `url(${project.bg})`
      );
    }
  }, [isFocused, focusRef, project.bg]);

  return (
    <div className={`Project ${isFocused ? 'Focused' : ''}`} ref={focusRef}>
      <img
        className="responsive-img"
        src={project.img}
        alt={project.title}
        data-index={index}
        onClick={onClick}
        loading="lazy"
      />
      {isFocused ? <div>{project.title}</div> : null}
    </div>
  );
};

export default Project;

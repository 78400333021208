import AdminDashboard from '../../assets/projects/admin-dashboard.webp';
import Battleship from '../../assets/projects/battleship.webp';
import Calculator from '../../assets/projects/calculator.webp';
import EtchASketch from '../../assets/projects/etch-a-sketch.webp';
import GoogleHomepage from '../../assets/projects/google-homepage.webp';
import Library from '../../assets/projects/library.webp';
import MemoryGame from '../../assets/projects/memory-game.webp';
import RestaurantPage from '../../assets/projects/restaurant-page.webp';
import ShoppingCart from '../../assets/projects/shopping-cart.webp';
import SignUpForm from '../../assets/projects/sign-up-form.webp';
import TicTacToe from '../../assets/projects/tic-tac-toe.webp';
import ToDoList from '../../assets/projects/to-do-list.webp';
import WeatherApp from '../../assets/projects/weather-app.webp';

import AdminDashboardBG from '../../assets/bg/birds.webp';
import BattleshipBG from '../../assets/bg/ship.webp';
import CalculatorBG from '../../assets/bg/calculator.webp';
import EtchASketchBG from '../../assets/bg/sketch.webp';
import GoogleHomepageBG from '../../assets/bg/google.webp';
import LibraryBG from '../../assets/bg/library.webp';
import MemoryGameBG from '../../assets/bg/game.webp';
import RestaurantPageBG from '../../assets/bg/restaurant.webp';
import ShoppingCartBG from '../../assets/bg/shopping.webp';
import SignUpFormBG from '../../assets/bg/signup.webp';
import TicTacToeBG from '../../assets/bg/tictactoe.webp';
import ToDoListBG from '../../assets/bg/todolist.webp';
import WeatherBG from '../../assets/bg/weather.webp';

import { v4 as uuidv4 } from 'uuid';

const projectData = [
  {
    img: ShoppingCart,
    title: 'Shopping Cart',
    subtitle: 'React: Routes and global state',
    description:
      'An online store template page built using React with shared global state across multiple routes.',
    source:
      'https://github.com/Chadwick-B/theodinproject/tree/main/shopping-cart',
    page: 'https://chadwick-b.github.io/theodinproject/shopping-cart/#/',
    bg: ShoppingCartBG,
    id: uuidv4(),
  },
  {
    img: MemoryGame,
    title: 'Memory Game',
    subtitle: 'React: Hooks/Lifecycle Methods',
    description:
      "A game in which the objective is to memorize which character icons you've clicked. Built using React to learn component lifecycles and hooks.",
    source:
      'https://github.com/Chadwick-B/theodinproject/tree/main/memory-card',
    page: 'https://chadwick-b.github.io/theodinproject/memory-card/',
    bg: MemoryGameBG,
    id: uuidv4(),
  },
  {
    img: AdminDashboard,
    title: 'Admin Dashboard',
    subtitle: 'Intermediate HTML/CSS: Advanced Grid/Flexbox',
    description:
      'A template page for an administrator dashboard built using the box model with CSS Grid and Flexbox.',
    source:
      'https://github.com/Chadwick-B/theodinproject/tree/main/admin-dashboard',
    page: 'https://chadwick-b.github.io/theodinproject/admin-dashboard/',
    bg: AdminDashboardBG,
    id: uuidv4(),
  },
  {
    img: SignUpForm,
    title: 'Sign Up Form',
    subtitle: 'Intermediate HTML/CSS: Form styling and light/dark mode queries',
    description:
      'A template sign-up page for any online service using proper form styling and media queries to toggle between light and dark mode.',
    source:
      'https://github.com/Chadwick-B/theodinproject/tree/main/sign-up-form',
    page: 'https://chadwick-b.github.io/theodinproject/sign-up-form/',
    bg: SignUpFormBG,
    id: uuidv4(),
  },
  {
    img: Battleship,
    title: 'Battleship',
    subtitle: 'Vanilla JS: Testing with Jest',
    description:
      'The classic game of battleship, playing up against an AI with unit tests built for each AI / Player function.',
    source: 'https://github.com/Chadwick-B/theodinproject/tree/main/battleship',
    page: 'https://chadwick-b.github.io/theodinproject/battleship/',
    bg: BattleshipBG,
    id: uuidv4(),
  },
  {
    img: RestaurantPage,
    title: 'Restaurant Page',
    subtitle: 'Vanilla JS: Webpack and dynamic rendering',
    description:
      'A template restaurant page using dynamically loaded data bundled together with webpack.',
    source:
      'https://github.com/Chadwick-B/theodinproject/tree/main/restaurant-page',
    page: 'https://chadwick-b.github.io/theodinproject/restaurant-page/',
    bg: RestaurantPageBG,
    id: uuidv4(),
  },
  {
    img: ToDoList,
    title: 'To-do List',
    subtitle: 'Vanilla JS: Object-oriented principles',
    description:
      'Using SOLID principles and loosely coupled objects to create and store todos.',
    source: 'https://github.com/Chadwick-B/theodinproject/tree/main/to-do-list',
    page: 'https://chadwick-b.github.io/theodinproject/to-do-list/',
    bg: ToDoListBG,
    id: uuidv4(),
  },
  {
    img: Library,
    title: 'Library',
    subtitle: 'Vanilla JS: Learning Objects / Classes',
    description:
      'Making use of objects and constructors to create simple book objects.',
    source: 'https://github.com/Chadwick-B/theodinproject/tree/main/library',
    page: 'https://chadwick-b.github.io/theodinproject/library/',
    bg: LibraryBG,
    id: uuidv4(),
  },
  {
    img: WeatherApp,
    title: 'Weather App',
    subtitle: 'Vanilla JS: Using APIs with Async / Await',
    description:
      'A weather app using location data paired with an asynchronous fetch from a weather API.',
    source:
      'https://github.com/Chadwick-B/theodinproject/tree/main/weather-app',
    page: 'https://chadwick-b.github.io/theodinproject/weather-app/',
    bg: WeatherBG,
    id: uuidv4(),
  },
  {
    img: TicTacToe,
    title: 'Tic-tac-toe',
    subtitle: 'Vanilla JS: Factory Functions and Modules',
    description:
      'Using common Javascript patterns to create tic-tac-toe rules and an AI.',
    source:
      'https://github.com/Chadwick-B/theodinproject/tree/main/tic-tac-toe',
    page: 'https://chadwick-b.github.io/theodinproject/tic-tac-toe/',
    bg: TicTacToeBG,
    id: uuidv4(),
  },
  {
    img: EtchASketch,
    title: 'Etch-A-Sketch',
    subtitle: 'Fundamentals: Javascript 01',
    description:
      'The first project that actively manipulates the DOM and attaches event listeners to create a functional Etch-A-Sketch',
    source:
      'https://github.com/Chadwick-B/theodinproject/tree/main/etch-a-sketch',
    page: 'https://chadwick-b.github.io/theodinproject/etch-a-sketch/',
    bg: EtchASketchBG,
    id: uuidv4(),
  },
  {
    img: Calculator,
    title: 'Calculator',
    subtitle: 'Fundamentals: Javascript 02',
    description:
      'Creating multiple buttons with event listeners that then use combined data to calculate the answer.',
    source: 'https://github.com/Chadwick-B/theodinproject/tree/main/calculator',
    page: 'https://chadwick-b.github.io/theodinproject/calculator/',
    bg: CalculatorBG,
    id: uuidv4(),
  },
  {
    img: GoogleHomepage,
    title: 'Google Homepage',
    subtitle: 'Fundamentals: Basic HTML / CSS',
    description:
      'Learning the basics of HTML / CSS by imitating design functionality.',
    source:
      'https://github.com/Chadwick-B/theodinproject/tree/main/google-homepage',
    page: 'https://chadwick-b.github.io/theodinproject/google-homepage/',
    bg: GoogleHomepageBG,
    id: uuidv4(),
  },
];

export default projectData;

import { v4 as uuidv4 } from 'uuid';

const skillData = [
  {
    name: 'React',
    description:
      'Reusable UI components created with Javascript/JSX.\n\nMy Strengths:\n-Javascript fundamentals\n-Clean, reusable components\n-Understanding of memoization, avoiding rerenders\n-Context API\n\nTools:\n-Redux\n-Create React App\n-Vite',
    id: uuidv4(),
  },
  {
    name: 'HTML / CSS',
    description:
      'General page layout and styling.\n\nMy Strengths:\n-Box model\n-Responsive design\n-Semantic HTML\n-Media queries',
    id: uuidv4(),
  },
  {
    name: 'Javascript / Typescript',
    description:
      'Object-oriented scripting language meant to handle web page interaction.\n\nMy Strengths:\n-Understanding of the DOM, event loops\n-Strong understanding of data structures & algorithms\n-Experience with object-oriented languages\n-Common design patterns',
    id: uuidv4(),
  },
  {
    name: 'Testing',
    description:
      'Testing individual functions or application functionality using mock or client simulated data.\n\nTools:\n-Puppeteer\n-Jest',
    id: uuidv4(),
  },
  {
    name: 'Back-End',
    description:
      'Server-side application development writing web services and APIs to be integrated with the front-end.\n\nTools:\n-Node.js + Express\n-ASP.NET',
    id: uuidv4(),
  },
  {
    name: 'Version Control',
    description:
      'Managing and tracking changes to source code, allowing for concurrent work without damaging conflicts and the opportunity to roll back.\n\nMy Strengths:\n-Knowledge of primary git commands\n-Branching and merging\n\nWant to learn:\n-Working in a group setting',
    id: uuidv4(),
  },
];

export default skillData;

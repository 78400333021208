import { FocusableLink } from '../../components/Link';

const ContactLink = (props) => {
  const { href, children } = props;
  return (
    <FocusableLink
      className="Contact__Link flex justify-center align-center circle"
      href={href}
    >
      {children}
    </FocusableLink>
  );
};

export default ContactLink;

import { useEffect, useRef } from 'react';

const useKeyDown = (targetKey, callback, useCapture) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const downHandler = (e) => {
      if (e.key === targetKey) callbackRef.current(e);
    };

    window.addEventListener('keydown', downHandler, useCapture);
    return () => window.removeEventListener('keydown', downHandler, useCapture);
  }, [targetKey, useCapture]);
};

export default useKeyDown;

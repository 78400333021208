import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  focus: null,
  rects: [],
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    add: (state, action) => {
      state.rects.push(action.payload);
    },
    remove: (state, action) => {
      state.rects = state.rects.filter((f) => f.id !== action.payload);
    },
    focus: (state, action) => {
      if (state.focus !== action.payload) state.focus = action.payload;
    },
    up: (state) => {
      const focus = state.rects.find((f) => state.focus === f.id);
      if (focus) {
        let nearestNeighbor, nearestDist;
        for (const rect of state.rects) {
          if (rect.id === focus.id) continue;
          const dist = focus.midy - rect.midy;
          if (dist > 0) {
            if (focus.right >= rect.left && focus.left <= rect.right) {
              if (!nearestNeighbor || dist < nearestDist) {
                nearestNeighbor = rect;
                nearestDist = dist;
              } else if (dist === nearestDist) {
                const hDist = Math.abs(focus.midx - rect.midx);
                const hDistLast = Math.abs(focus.midx - nearestNeighbor.midx);
                if (hDist < hDistLast) {
                  nearestNeighbor = rect;
                  nearestDist = dist;
                }
              }
            }
          }
        }

        if (nearestNeighbor) state.focus = nearestNeighbor.id;
      } else {
        let lastDefaultId;
        for (const rect of state.rects) {
          if (rect.default) lastDefaultId = rect.id;
        }

        if (lastDefaultId) state.focus = lastDefaultId;
      }
    },
    down: (state) => {
      const focus = state.rects.find((f) => state.focus === f.id);
      if (focus) {
        let nearestNeighbor, nearestDist;
        for (const rect of state.rects) {
          if (rect.id === focus.id) continue;
          const dist = rect.midy - focus.midy;
          if (dist > 0) {
            if (focus.right >= rect.left && focus.left <= rect.right) {
              if (!nearestNeighbor || dist < nearestDist) {
                nearestNeighbor = rect;
                nearestDist = dist;
              }
            }
          }
        }

        if (nearestNeighbor) state.focus = nearestNeighbor.id;
      } else {
        let lastDefaultId;
        for (const rect of state.rects) {
          if (rect.default) lastDefaultId = rect.id;
        }

        if (lastDefaultId) state.focus = lastDefaultId;
      }
    },
    left: (state) => {
      const focus = state.rects.find((f) => state.focus === f.id);
      if (focus) {
        let nearestNeighbor, nearestDist;
        for (const rect of state.rects) {
          if (rect.id === focus.id) continue;
          const dist = focus.midx - rect.midx;
          if (dist > 0) {
            if (focus.bottom >= rect.top && focus.top <= rect.bottom) {
              if (!nearestNeighbor || dist < nearestDist) {
                nearestNeighbor = rect;
                nearestDist = dist;
              }
            }
          }
        }

        if (nearestNeighbor) {
          state.focus = nearestNeighbor.id;
        }
      } else {
        let lastDefaultId;
        for (const rect of state.rects) {
          if (rect.default) lastDefaultId = rect.id;
        }

        if (lastDefaultId) state.focus = lastDefaultId;
      }
    },
    right: (state) => {
      const focus = state.rects.find((f) => state.focus === f.id);
      if (focus) {
        let nearestNeighbor, nearestDist;
        for (const rect of state.rects) {
          if (rect.id === focus.id) continue;
          const dist = rect.midx - focus.midx;
          if (dist > 0) {
            if (focus.bottom >= rect.top && focus.top <= rect.bottom) {
              if (!nearestNeighbor || dist < nearestDist) {
                nearestNeighbor = rect;
                nearestDist = dist;
              }
            }
          }
        }

        if (nearestNeighbor) state.focus = nearestNeighbor.id;
      } else {
        let lastDefaultId;
        for (const rect of state.rects) {
          if (rect.default) lastDefaultId = rect.id;
        }

        if (lastDefaultId) state.focus = lastDefaultId;
      }
    },
  },
});

export const { add, remove, focus, up, down, left, right } =
  navigationSlice.actions;

export default navigationSlice.reducer;

import { useRef, useCallback } from 'react';
import useGamepads from './useGamepads';

export default function useGamepadCallbacks(
  callbacks = {
    onButtonDown: () => {},
    onButtonUp: () => {},
    onButtonChange: () => {},
    onAxisChange: () => {},
  }
) {
  const saveRef = useRef([]);

  const storeSnapshot = useCallback((gamepad) => {
    saveRef.current = {
      ...saveRef.current,
      [gamepad.index]: {
        buttons: gamepad.buttons.map((btn) => ({
          pressed: btn.pressed,
          touched: btn.touched,
          value: btn.value,
        })),
        axes: [...gamepad.axes],
      },
    };
  }, []);

  const checkInitital = useCallback(
    (gamepad) => {
      for (let i = 0; i < gamepad.buttons.length; i++) {
        if (gamepad.buttons[i].value > 0) {
          const data = {
            gamepad: gamepad,
            index: i,
            button: gamepad.buttons[i],
          };

          callbacks.onButtonChange?.(data);
          if (gamepad.buttons[i].pressed) callbacks.onButtonDown?.(data);
        }
      }

      for (let i = 0; i < gamepad.axes.length; i++) {
        if (gamepad.axes[i] > 0) {
          callbacks.onAxisChange?.({
            gamepad: gamepad,
            index: i,
            value: gamepad.axes[i],
          });
        }
      }
    },
    [callbacks]
  );

  const checkGamepad = useCallback(
    (gamepad) => {
      const storedGamepad = saveRef.current[gamepad.index];

      for (let i = 0; i < gamepad.buttons.length; i++) {
        const current = gamepad.buttons[i];
        const last = storedGamepad.buttons[i];

        if (current.value !== last.value) {
          const data = {
            gamepad: gamepad,
            index: i,
            button: gamepad.buttons[i],
          };

          callbacks.onButtonChange?.(data);
          if (current.pressed !== last.pressed) {
            current.pressed
              ? callbacks.onButtonDown?.(data)
              : callbacks.onButtonUp?.(data);
          }
        }
      }

      for (let i = 0; i < gamepad.axes.length; i++) {
        const current = gamepad.axes[i];
        const last = storedGamepad.axes[i];

        if (current !== last) {
          callbacks.onAxisChange?.({
            gamepad: gamepad,
            index: i,
            value: gamepad.axes[i],
          });
        }
      }
    },
    [callbacks]
  );

  useGamepads((gamepad) => {
    if (!saveRef.current[gamepad.index]) {
      checkInitital(gamepad);
    } else {
      checkGamepad(gamepad);
    }

    storeSnapshot(gamepad);
  });
}

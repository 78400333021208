import { useState, useEffect } from 'react';

const Clock = (props) => {
  const { className = '' } = props;
  const [date, setDate] = useState(new Date());
  const update = () => setDate(new Date());

  useEffect(() => {
    const interval = setInterval(update, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={className}>
      {date.toLocaleTimeString([], { timeStyle: 'short' })}
    </div>
  );
};

export default Clock;

import { useState } from 'react';
import useBrightness from '../../hooks/useBrightness.js';
import Testimonials from '../../features/Testimonials';
import { FocusableButton } from '../../components/Button';
import Github from './Github.js';
import LinkedIn from './LinkedIn.js';
import Twitter from './Twitter.js';
import Email from './Email.js';

const Contact = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  useBrightness(0.6);

  const buttonNode = isFormOpen ? (
    <>&#128517; Not added yet</>
  ) : (
    <>&#127849; Let's go</>
  );
  const interactHandler = () => {
    setIsFormOpen(true);
  };

  return (
    <main className="Contact FadeIn">
      <div className="Contact__Work text-center">
        <div className="b xl">Start a project</div>
        <div className="l">
          If you're interested in working with me, let's set up a time to chat!
        </div>
        <FocusableButton onInteract={interactHandler}>
          {buttonNode}
        </FocusableButton>
      </div>
      <div className="Contact__Links">
        <Github />
        <LinkedIn />
        <Twitter />
        <Email />
      </div>
      {/* <Testimonials /> */}
    </main>
  );
};

export default Contact;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFocusableRef } from '../../hooks/SpatialNavigation';

const FocusableNavItem = (props) => {
  const { className, children, path, defaultFocus = false } = props;
  const [isFocused, focusRef, forceFocus] = useFocusableRef(defaultFocus);
  let navigate = useNavigate();

  useEffect(() => {
    if (isFocused) navigate(path);
  }, [isFocused, navigate, path]);

  return (
    <div
      className={`${className} ${isFocused ? 'Focused' : ''}`}
      onClick={forceFocus}
      ref={focusRef}
    >
      {children}
    </div>
  );
};

export default FocusableNavItem;
